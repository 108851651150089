<template>
  <div class="user-auth-info">
    <div class="header">
      <div class="avatar">
        <van-image
          round
          :src="avatar"
          class="img"
        />
      </div>
      <div class="phone"><span>当前账号</span> {{ phone }}</div>
    </div>
    <div class="content">
      <div class="list">
        <div class="item">
          <div class="label">姓名</div>
          <div class="value">{{ userInfos.realName }}</div>
        </div>
        <div class="item">
          <div class="label">证件号码</div>
          <div class="value">{{ userInfos.idCard }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getOssUrl } from '@/utils/oss'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      avatar: '',
      phone: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  async mounted() {
    this.avatar = await getOssUrl(this.userInfos.headImgUrl)
    this.phone = this.userInfos.eaUserName
  }
}
</script>
<style lang="scss" scoped>
.user-auth-info {
  .header {
    padding: 48px 0;
    text-align: center;
    .avatar {
      .img {
        width: 74px;
        height: 74px;
      }
    }
    .phone {
      padding-top: 10px;
      font-size: 16px;
      color: #2E3135;
      span {
        color: #999;
      }
    }
  }
  .content {
    padding: 0 24px;
    .list {
      padding: 16px;
      border-radius: 8px;
      background: #fcf4ec;
      .item {
          display: flex;
          align-items: center;
          padding: 8px 0;
          font-size: 16px;
          color: #2E3135;
        .label {
            flex: 1;
            font-size: 16px;
            margin-right: 16px;
          }
        .value {
            font-size: 16px;
        }
      }
    }
  }
}
</style>
